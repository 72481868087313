<template>
  <v-container>
    <v-row class="about-body primary--text text-center">
      <v-col cols="12">
        <h2 style="margin-bottom: 30px;">Terms and Conditions</h2>
        <iframe :src="pdfLink" frameborder="0" scrolling="auto" height="800px" width="100%"></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

const termsFilePath = require('../../assets/terms_and_conditions/09062021.pdf');

export default {
  name: 'termsAndConditions',
  components: {},
  data: function() {
    return {
      pdfLink: termsFilePath,
    };
  },
};
</script>

<style scoped>
.about-body {
  margin: 0;
}
</style>
